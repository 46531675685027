@keyframes placeHolderShimmer {
  0% {
    background-position: -900px 0;
  }
  100% {
    background-position: 900px 0;
  }
}

.animated-background {
  animation-duration: 6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 900px 104px;
  height: 30px;
  position: relative;
}

.animated-background + .animated-background {
  margin-top: 25px; /* or whatever spacing you prefer */
}


