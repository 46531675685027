/* src/Footer.css */
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding: .5rem;
    border-top: 1px solid #e0e0e0;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    font-size: 8px;
    z-index: 1002;
  }

  .footer a {
    color: inherit;       /* Use the color of the parent element */
    text-decoration: none; /* Removes the underline */
}

.footer a:hover {
    text-decoration: underline; /* Adds an underline on hover for better UX */
}

  