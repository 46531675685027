/* src/Sidebar.css */

.Sidebar {
  position: fixed;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 20px;
  background-color: #f8f9fa;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  z-index: 1000;
  display: block;
}

.Sidebar.hide {
  transform: translateX(-75%);
}


/*adjust nav width to 85% of sidebar*/
.Sidebar nav {
  width: 100%;
}

.Sidebar.open {
  transform: translateX(0);
}


/* Styles for the expandable resume section */
.resume-section-toggle {
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  text-decoration: none;
    font-size: 18px;
    display: block;
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;

}


.resume-section-toggle:hover {
  background-color: #e0e0e0;
}


/* Styling for the child items of the resume section */
.subnav {
  font-size: 14px !important; /* Slightly smaller font */
  padding-left: 15px !important; /* Indentation for visual hierarchy */
}


/*translate main-container to the right when sidebar is open and back to the left when closed, also reduce width of main-container*/

.Sidebar.open ~ .main-container { 
  transform: translateX(250px);
  width: calc(100% - 250px);
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;

}


.Sidebar.open ~ .message-banner {
  transform: translateX(-25px); 
  transition: transform 0.3s ease-in-out;
}

.Sidebar.open ~ .loading-banner {
  transform: translateX(-25px); 
  transition: transform 0.3s ease-in-out;
}



.sidebar-container {
  height: calc(100vh - 35px);  /* Adjust the 20px to your desired padding */
  padding-bottom: 35px;  /* Corresponding padding at the bottom */
  overflow-y: auto;
}

.sidebar-container::-webkit-scrollbar {
  width: 8px;  /* Adjust width for vertical scrollbars */
}

.sidebar-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;  /* Or any color you prefer */
  border-radius: 4px;  /* Optional */
}

.sidebar-container::-webkit-scrollbar-thumb:hover {
  background-color: grey;  /* Or any color you prefer */
}



.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-header .header-link {
  margin-left: 20px; 
}

.sidebar-header .hamburger {
  margin-right: 0px; 
}


.Sidebar nav ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.Sidebar nav ul li a {
  text-decoration: none;
  color: #333;
  margin-top: 20px;
}

.Sidebar nav ul li {
  margin-top: 10px 0; /* Add some vertical space between the items */
}

.Sidebar nav ul li a {
  text-decoration: none;
  color: #333;
  font-size: 18px; /* Make the text a bit bigger */
  display: block; /* Make the entire list item clickable, not just the text */
  padding: 10px; /* Add some padding */
  border-radius: 4px; /* Optional: round the corners a bit */
}

.sidebarloginlogout {
  text-decoration: none;
  color: #333;
  font-size: 18px; /* Make the text a bit bigger */
  display: block; /* Make the entire list item clickable, not just the text */
  padding: 10px; /* Add some padding */
  border-radius: 4px; /* Optional: round the corners a bit */
  cursor: pointer;
}

.sidebarloginlogout:hover {
  background-color: #ddd; /* Change the background when hovering */
  color: #000; /* Make the text darker when hovering */
}


.Sidebar nav ul li a:hover {
  background-color: #ddd; /* Change the background when hovering */
  color: #000; /* Make the text darker when hovering */
}

.Sidebar nav ul li a.active {
  background-color: #bbb; /* Change the background for the active link */
  color: #000; /* Make the text darker for the active link */
}

/*navicon is up against the right side of the sidebar*/
.navicon {
  float: right;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  padding-right: 7px;
}

.hamburger-box {
  width: 30px;   /* Decreased from 40px */
  height: 20px;  /* Decreased from 24px */
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;  /* Decreased from 40px */
  height: 3px;  /* Decreased from 4px */
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -8px;  /* Adjusted from -10px */
}

.hamburger-inner::after {
  bottom: -8px; /* Adjusted from -10px */
}


.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.header-link {
  color: inherit; /* makes the color the same as the parent element */
  text-decoration: none; /* removes the underline */
}

.header-link h1 {
  color: #333; /* change to whatever color you want */
}



/* Media query for screens less than 768px wide */
/* hides sidebar fully*/

@media (max-width: 767px) {

  .Sidebar {
    transform: translateX(-100%);
  }

  .Sidebar.hide {
    transform: translateX(-100%);
  }



  .mobile-header-for-small-screens {
    display: block;
    position: fixed;
    z-index: 1001 ;
  }

  .hamburger {
    display: none;
  }

  .Sidebar.open ~ .main-container {
    transform: translateX(0);
    width: auto;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  
  }
}

@media (max-width: 767px) {
  .Sidebar.show {
    transform: translateX(-100%);
  }
  .Sidebar {
    transform: translateX(0);
  }
}

/* mobile header */

.mobile-header-for-small-screens {

  position: fixed;
  top: 0;
  /* Other styles as needed */
}

.mobile-header-for-small-screens svg {
  width: 30px; /* Set the width of the icon */
  height: 30px; /* Set the height of the icon */
  color: #000; /* Set the color of the icon */
}

/* hide mobile header width greater than 767px */

@media (min-width: 768px) {
  .mobile-header-for-small-screens {
    display: none;
  }
}
